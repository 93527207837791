import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useIsMount } from '../../hooks/index';
import './FilterButton.scss';

//TODO переименовать
const RequiresAttentionButton = ({
                                     name = '',
                                     active = false,
                                     selectedItems = 0,
                                     handlerClick = () => {
                                     },
                                    count = null,
                                 }) => {
    return (
        <div
            className={cn('filter-button', 'warning')}
        >
            <div
                className={cn(
                    'filter-button__title',
                    { 'active': active },
                )}
                onClick={() => {
                    handlerClick();
                }}
            >
                <TitleButton
                    title={name}
                    lengthSelectedItems={selectedItems}
                />
                {count > 0 && <span className={'badge'}>{count}</span>}
            </div>
        </div>
    );
}

const FilterButtonSimple = (props) => {
    return (
        <FilterButton
            {...props}
            simple={true}
            className={cn({'fixed-width': props.fixedWidth}, { 'fixed-width__mobile': props.mobile })}
        />
    )
}

/**
 *
 * @param {string} name
 * @param {Array} list массив элементов
 * @param {Array} filter массив выбранных элементов
 * @param {Function} onChange
 * @param {Function} onClear
 * @param {boolean} disabled
 * @param {boolean} loading
 * @param {boolean} simple отключает множественный выбор
 * @param {string} className
 * @param {boolean} search включает строку поиска
 * @param {boolean} sort включает сортировку по алфавиту
 * @param {Object} defaultValue значение фильтра по умолчанию
 * @returns {JSX.Element}
 * @constructor
 */
const FilterButton = ({
                          name = '',
                          list = [],
                          filter = [],
                          onChange = () => {
                          },
                          onClear = () => {
                          },
                          disabled = false,
                          loading = false,
                          simple = false,
                          className = '',
                          search = false,
                          sort = true,
                          defaultValue = null
                      }) => {

    const buttonRef = useRef();
    const searchField = useRef();
    const dropdownPlaceholder = useRef();
    const [openDropdown, setOpenDropdown] = useState(false);
    const [selectedItems, setSelectedItems] = useState({});
    const [values, setValues] = useState([]);
    const [isLoading, setLoading] = useState(loading);
    const [title, setTitle] = useState(name);
    const [visibleList, setVisibleList] = useState([]);

    const isMount = useIsMount();

    useEffect(() => {
        setDefaultValue();

        if (simple && filter.length > 0) {
            setTitle(() => list.find(item => item.value === filter[0].value)?.name);
        }

        document.addEventListener('click', clickOutsideHandler, false);

        return () => {
            document.removeEventListener('click', clickOutsideHandler, false);
        }
    }, [list]);


    useEffect(() => {
        !isMount && onChange(selectedItems);
    }, [values])

    useEffect(() => {
        sort ? setVisibleList(list.sort(abcSort)) : setVisibleList(list);
    }, [list]);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    useEffect(() => {
        setSelectedItems(filter);
        if (simple && filter.length > 0 && !isMount) {
            setTitle(() => list.find(item => item.value === filter[0].value).name);
        }

    }, [filter]);

    useEffect(() => {
        if (!isMount) {

            if (dropdownPlaceholder.current) {
                if (document.documentElement.clientWidth < dropdownPlaceholder.current.getBoundingClientRect().right) {
                    dropdownPlaceholder.current.style.left = '-145px';
                }
            }

            if (!openDropdown) {
                setVisibleList(list);
            } else {
                if (filter.length > 0) {
                    setVisibleList(selectedToTopList());
                }
            }
        }
    }, [openDropdown]);

    const selectedToTopList = () => {
        //формируем массив с выбранными элементами
        let topArr = [];
        filter.forEach((item) => {
            topArr.push(list.filter((i) => i.value === item.value)[0]);
        });

        //удаляем из списка элементов, выбранные элементы
        let newList = [...list];
        filter.forEach((item) => {
            newList.splice(
                newList.findIndex((i) => i.value === item.value),
                1
            );
        });


        //сортируем массив без выбранных элементов по алфавиту
        sort && newList.sort(abcSort);

        // элемент с value = 0 (это элемент "все филиалы/менеджеры") в верх списка
        newList.sort(
            (a, b) => a.value === 0 ? -1 : b.value === 0 ? 1 : 0
        );

        //слияние массива с выбранными элементами и массива без выбранных элементов.
        return topArr.concat(
            newList
        );
    }

    const setDefaultValue = () => {
        if (simple && filter.length <= 0) {
            filter.push({
                value: list[0].value
            });
        }
    }

    const clickOutsideHandler = (event) => {
        if (!buttonRef.current?.contains(event.target)) {
            setOpenDropdown(false);
        }
    }

    const handlerChange = (data) => {
        if (!simple) {
            if (data.action === 'add') {
                setSelectedItems(selectedItems => [...selectedItems, {
                    value: data.value
                }]);

                setValues((values) => {
                    return [
                        ...values,
                        { value: data.value }
                    ];
                })
            }

            if (data.action === 'remove') {
                setSelectedItems(selectedItems => selectedItems.filter(item => item.value !== data.value));
                setValues(values => values.filter(item => item.value !== data.value));
            }

        } else {
            if (data.action === 'add') {
                setSelectedItems([{
                    value: data.value
                }]);

                setValues([{
                    value: data.value
                }]);

                setTitle(data.name);
            }
        }
    }

    const clearField = (e) => {
        e.preventDefault();
        searchField.current.value = '';
        setVisibleList(list);
    }

    const resetFilter = (e) => {
        onClear(true);
        e.stopPropagation();
        e.preventDefault();
    }

    const handlerSearchItem = (e) => {
        if (e.target.value.length > 0) {
            const newList = list.filter((item) => (
                item.name.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1
            ));
            setVisibleList(newList);
        } else {
            setVisibleList(list);
        }
    }

    const abcSort = (a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    };

    const showBtnClear = () => {
        if (simple) {
            //TODO переделать отображение иконки "очистить фильтр" на использоваение defaultValue
            if(defaultValue) {
                return !(filter.length === 1 && filter[0].value === defaultValue.value);
            }
            return !(filter.length === 1 && filter[0].value === list[0].value);
        } else {
            return selectedItems.length > 0
        }

    }

    return (
        <div
            className={cn('filter-button', { 'disabled': disabled })}
            ref={buttonRef}
        >
            <div
                className={cn(
                    'filter-button__title',
                    { 'selected': openDropdown },
                    { 'active': !!selectedItems.length },
                    className
                )}
                onClick={() => {
                    !disabled && setOpenDropdown(openDropdown => !openDropdown);
                }}
            >
                <TitleButton
                    title={title}
                    selectedItems={selectedItems.length}
                    simple={simple}
                />
                {(showBtnClear() && !disabled) && <i
                    className={cn('filter-button__btn-clear', 'pi', 'pi-times')}
                    onClick={resetFilter}
                />}
            </div>
            <div className={cn('filter-button__dropdown', {'active': openDropdown})} ref={dropdownPlaceholder}>
                {isLoading && <div className={cn('filter-button__dropdown-loading')} />}

                {search && <div className={cn('search-field')}>
                    <i
                        onClick={clearField}
                        className={cn('search-field__cross', 'pi', 'pi-times')}
                    />
                    <input
                        ref={searchField}
                        onChange={handlerSearchItem}
                        type="text"
                        className={cn('search-field__input')}
                        placeholder={'Искать...'}
                    />
                </div>}

                <ul>
                    {visibleList && visibleList.map((item, key) => {

                        const selectedItem2 = !!selectedItems.filter(select => select.value === item.value).length
                        return (
                            <Row
                                key={key}
                                item={item}
                                isSelect={selectedItem2}
                                handlerChange={handlerChange}
                            />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

const Row = ({ item, isSelect, handlerChange }) => {
    return (
        <li className={cn({ 'select': isSelect })}
            onClick={() => {
                const action = isSelect ? 'remove' : 'add';
                handlerChange({
                    value: item.value,
                    name: item.name,
                    action,
                })
            }}
        >
            {isSelect && <i className="pi pi-check" />}
            {item.name}</li>
    );
};

const TitleButton = ({ title, selectedItems, simple = false }) => {
    return (
        <span>
            {title} {simple ? null : (selectedItems >= 1) && <>: <span>{selectedItems}</span></>}
        </span>
    )
};

export {
    RequiresAttentionButton,
    FilterButtonSimple,
    FilterButton,
}