import cn from "classnames";
import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import analyticsChartService from "../../../../services/analyticsChartService";

import Block1Chart from "./Charts/Block1Chart";
import Block2Chart from "./Charts/Block2Chart";
import Block3Chart from "./Charts/Block3Chart";
import Block4Chart from "./Charts/Block4Chart";

import "../applications/Charts.scss";
import "./Charts.scss";
import Empty from "../applications/Charts/Empty";

const Charts = ({analyticsStore}) => {
    const {filter} = analyticsStore;

    const [block1, setBlock1] = useState({})
    const [block2, setBlock2] = useState({})
    const [block4, setBlock4] = useState({})

    useEffect(() => {

        const promises = [
            analyticsChartService.getDataSourceRating(filter),
            analyticsChartService.getDataSourceReplies(filter),
            analyticsChartService.getDataSourceReplyTimes(filter)
        ]

        Promise.all(promises).then(([dataSourceRating, dataSourceReplies, dataSourceReplyTimes]) => {
            setBlock1(dataSourceRating);
            setBlock2(dataSourceReplies);
            setBlock4(dataSourceReplyTimes);
        })
    }, [JSON.stringify(filter)])

    return (
        <div className={'charts__wrapper'}>
            {block1.length > 0 ? <div className={cn('chart')}>
                <div className={cn('chart__title')}>Рейтинг</div>
                <div className={cn('chart__content')}>
                    {block1.map((item, key) => {
                        return <Block1Chart
                            data={item}
                            index={key}
                            key={key}
                            fillColor={item.lightColor}
                            strokeColor={item.color}
                            visibleLabels={true}
                            visibleBullets={true}
                            postfix=''
                            minHeight={item.minValue}
                            maxHeight={item.maxValue}
                            nameField='value'
                        />
                    })}
                </div>
            </div>  : <Empty title={'Рейтинг'}/>}

            {block2.length > 0 ? <div className={cn('chart')}>
                <div className={cn('chart__title')}>Доля отзывов с ответом, %</div>
                <div className={cn('chart__content')}>
                    {block2.map((item, key) => {
                        if(item.maxPercentages > 0) {
                            return <Block3Chart
                                data={item}
                                index={key}
                                key={key}
                                fillColor={item.lightColor}
                                strokeColor={item.color}
                                visibleLabels={true}
                                visibleBullets={true}
                                postfix='%'
                                minHeight={0}
                                maxHeight={item.maxPercentages}
                                nameField='percentages'
                            />
                        }
                    })}
                </div>
            </div> : <Empty title={'Доля отзывов с ответом, %'}/>}

            {block2.length > 0 ? <div className={cn('chart')}>
                <div className={cn('chart__title')}>Отзывы</div>
                <div className={cn('chart__content')}>
                    {block2.map((item, key) => {
                        return <Block2Chart
                            data={item}
                            index={key}
                            key={key}
                            fillColor={item.color}
                            visibleLabels={true}
                            maxHeight={(item.maxValue * 1.1)}
                        />
                    })}
                </div>
            </div> : <Empty title={'Отзывы'}/>}

            {block4.length > 0 ? <div className={cn('chart')}>
                <div className={cn('chart__title')}>Среднее время ответа, часов</div>
                <div className={cn('chart__content')}>
                    {block4.map((item, key) => {
                        if(item.maxValue > 0) {
                            return <Block4Chart
                                data={item}
                                index={key}
                                key={key}
                                fillColor={item.color}
                                visibleLabels={true}
                                maxHeight={(item.maxValue * 1.1)}
                            />
                        }
                    })}
                </div>
            </div> : <Empty title={'Среднее время ответа, часов'}/>}
        </div>
    )
}

export default inject('analyticsStore')(observer(Charts));