import React, { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import cn from "classnames";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import claimsService from '../../../../../services/claims';
import okAnalyticsService from "../../../../../services/okAnalytics";

import './Claims.scss';
import { Button } from "primereact/button";
import { inject, observer } from "mobx-react";

const MAXLENGTH = 450;

const ClaimsSlideBar = ({
                            uiStore,
                            reviewId,
                            setClaimsAllowed,
                            setClaimStatus,
                            visibleRight,
                            setVisibleRight,
                            isMobile = false,
                            charLimit = false
                        }) => {

    const [subjectList, setSubjectList] = useState([]);
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        visibleRight && claimsService.getSubject()
            .then(data => {
                setSubjectList(data);
            }).finally(() => {
                setIsLoading(false);
            });
    }, [visibleRight]);

    useEffect(() => {
        setSubject(subjectList[0]?.value);
    }, [subjectList]);

    useEffect(() => {
        setIsValid(
            !!subject && (text.length >= 5)
        );
    }, [subject, text])

    const save = () => {
        setIsLoading(true);
        claimsService.saveClaim({ subject, text, reviewId })
            .then(() => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Жалоба отправлена',
                        detail: '',
                    }
                );

                setVisibleRight(false);
                setClaimsAllowed(false);
                setClaimStatus('submitted');

                okAnalyticsService.addEntity({
                    page: 'review_list',
                    target: 'claim_form',
                    event: 'send_claim',
                    info: {
                        review_id: reviewId,
                    }
                });

            })
            .catch(err => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: 'Ошибка отправки жалобы',
                        detail: err,
                    }
                )
                setIsLoading(false);
            });
    }

    return (
        <>
            <Sidebar
                visible={visibleRight}
                position="right"
                onHide={() => setVisibleRight(false)}
                className={cn('claims', { 'mobile': isMobile })}
                blockScroll={true}
                icons={() => (
                    <button
                        className={cn('p-link', 'close-text')}
                        onClick={() => setVisibleRight(false)}
                    >
                        Закрыть
                    </button>
                )}
            >
                {isLoading && <div className={'loader'}/>}
                <div className={cn('claims__title')}>
                    <h3>Обжаловать отзыв</h3>
                </div>

                <div className={cn('claims__body', 'main-form')}>

                    <div className={'text-field'}>
                        <label htmlFor="reason">Причина</label>
                        {subjectList.length > 0 && <Dropdown inputId="reason"
                                                             name="reason"
                                                             options={subjectList}
                                                             value={subject}
                                                             onChange={(e) => setSubject(e.value)}
                                                             optionLabel="label"
                        />}
                    </div>
                    <div className={'textarea-field'}>
                        <InputTextarea
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            rows={5}
                            cols={30}
                            autoResize
                            maxLength={charLimit ? MAXLENGTH : null}
                        />
                        {charLimit && <div className={cn('maxLength__wrapper')}>
                            <span className={cn('maxLength')}>Максимальное количество символов: {text.length}/{MAXLENGTH}</span>
                        </div>}

                    </div>
                    <div className={cn('btn-form')}>
                        <Button
                            label="Отправить"
                            onClick={save}
                            disabled={!isValid}
                        />
                    </div>
                </div>
            </Sidebar>
        </>
    )
}
export default inject('uiStore')(observer(ClaimsSlideBar));
