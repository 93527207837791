import React from "react";
import cn from "classnames";
import Setup from "../Setup";
import {TopFormFields} from "../../../../../stores/forms/topForm.store";
import {inject, observer} from "mobx-react";
import OkInputText from "../../../../common/OkInputText";

const NameCompany = ({geoStore}) => {

    const {form} = geoStore;

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Название</h3>
                </div>
                <div className={cn('block__form')}>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='fieldName1'
                                    type="text"
                                    name='fieldName1'
                                    required={form.fieldName1.required}
                                    value={form.fieldName1.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName1', e.target.value)
                                    }}
                                />
                                <label htmlFor='fieldName1'>
                                    Название компании
                                    {form.fieldName1.required && <span>*</span>}
                                </label>
                            </div>
                        </div>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='fieldName2'
                                    type="text"
                                    name='fieldName2'
                                    required={form.fieldName2.required}
                                    value={form.fieldName2.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName2', e.target.value)
                                    }}
                                />
                                <label htmlFor='fieldName2'>
                                    {form.fieldName2.required && <span>*</span>}
                                    Отправляемое название
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('title-field')}>
                            <h4>Настройка площадок</h4>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <Setup
                            disabled={!form.fieldName1.value.length}
                            fields={
                                [
                                    new TopFormFields({
                                        fieldName: 'name',
                                        fieldLabel: 'Название компании',
                                        fieldValue: form.fieldName1.value,
                                        fieldType: 'text',
                                        required: true
                                    }),
                                    new TopFormFields({
                                        fieldName: 'name2',
                                        fieldLabel: 'Отправляемое название',
                                        fieldValue: '',
                                        fieldType: 'text',
                                        required: false
                                    }),
                                ]
                            }
                            code={"firstSetup"}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default inject('geoStore')(observer(NameCompany));