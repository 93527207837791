import { action, observable } from "mobx";
import _ from "underscore";
import geoDataService from "../../services/geo/geo-category";
/*{
    "code": "firstSetup",
    "blocks": [
    {
        "source": "yandex",
        "name": "Яндекс.Карты",
        "active": false,
        "fields": [
            {
                "fieldName": "name",
                "fieldLabel": "Название компании",
                "fieldValue": "яндекс ",
                "required": true
            },
            {
                "fieldName": "name2",
                "fieldLabel": "Отправляемое название",
                "fieldValue": "отпр яндекс",
                "required": false
            }
        ]
    },
    {
        "source": "otzovik",
        "name": "Отзовик",
        "active": true,
        "fields": [
            {
                "fieldName": "name",
                "fieldLabel": "Название компании",
                "fieldValue": "отзовик",
                "required": true
            },
            {
                "fieldName": "name2",
                "fieldLabel": "Отправляемое название",
                "fieldValue": "отпр отзовик",
                "required": false
            }
        ]
    }
]
},*/

//TODO отрефачить работу с form.setupPlace.code[legalInfoSetup] && mainCategoryList !!!!

class GeoStore {

    @observable mainCategoryList = [];

    @observable form = {
        // первый блок
        fieldName1: { //Название-Название компании
            value: "",
            required: true,
            isValid: null,
        },
        fieldName2: { //Название-Отправляемое название
            value: "",
            required: false,
            isValid: null,
        },
        setupPlace: [
            {
                code: "firstSetup",
                blocks: [],
            },
            {
                code: "legalInfoSetup",
                blocks: [],
            },
            {
                code: "contactInfoSetup",
                blocks: [],
            },
            {
                code: "socialMediaSetup",
                blocks: [],
            },
            {
                code: "descriptionSetup",
                blocks: [],
            },
        ],
        //Бренд и код филиала-Бренд
        fieldName3: { //кода филиала
            value: "",
            required: true,
            isValid: null,
        },
        fieldName4: { //бренд
            value: "",
            required: true,
            isValid: null,
        },
        //Адрес
        fieldName5: { //страна
            value: "",
            required: true,
            isValid: null,
        },
        fieldName6: { //область
            value: "",
            required: true,
            isValid: null,
        },
        fieldName7: { //город
            value: "",
            required: false,
            isValid: null,
        },
        fieldName8: { //индекс
            value: "",
            required: false,
            isValid: null,
        },
        fieldName9: { //улица
            value: "",
            required: false,
            isValid: null,
        },
        fieldName10: { //номер дома
            value: "",
            required: false,
            isValid: null,
        },
        fieldName11: { //коментарий
            value: "",
            required: false,
            isValid: null,
        },
        coords_1: { //широта
            value: "",
            required: false,
            isValid: null,
        },
        coords_2: { //долгота
            value: "",
            required: false,
            isValid: null,
        },
        fieldName12: {
            value: "",
            required: false,
            isValid: null,
        },
        fieldName13: {
            value: "",
            required: false,
            isValid: null,
        },
        fieldName14: {
            value: [],
            required: false,
            isValid: null,
        },
        fieldName15: { //Номер телефона
            value: [],
            required: true,
            isValid: null,
        },
        fieldName16: { //Email адрес
            value: [],
            required: false,
            isValid: null,
        },
        fieldName17: { //веб сайт
            value: [],
            required: false,
            isValid: null,
        },
        fieldName18: { //соц сети
            value: [],
            required: false,
            isValid: null,
        },
        workTime: {
            value: [],
            required: false,
            isValid: null,
        },
        paymentTypes: {
            value: [],
            required: false,
            isValid: null,
        },
        fieldName19: { //Краткое описание компании
            value: [],
            required: false,
            isValid: null,
        },
        fieldName20: { //Полное описание описание компании
            value: [],
            required: false,
            isValid: null,
        },

    }

    modelPayment = [
        { code: 'cash', title: 'Наличные деньги', active: false },
        { code: 'card', title: 'Карта', active: false },
        { code: 'cashTransfers', title: 'Безнал. переводы', active: false },
        { code: 'electronicMoney', title: 'Электронные деньги', active: false },
    ]

    defaultTimeFrom = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 10, 0)
    defaultTimeTo = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 19, 0)

    week = [
        {
            index: 1,
            name: 'Понедельник',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
        },
        {
            index: 2,
            name: 'Вторник',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
        },
        {
            index: 3,
            name: 'Среда',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
        },
        {
            index: 4,
            name: 'Четверг',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
        },
        {
            index: 5,
            name: 'Пятница',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
        },
        {
            index: 6,
            name: 'Суббота',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
        },
        {
            index: 7,
            name: 'Воскресенье',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
        },
    ];


    @action saveInMainCategoryList = (data) => {
        this.mainCategoryList = data;
    }

    checkArrayField = () => {
        let fieldsError = [];
        Object.keys(this.form).map(key => {
            if (typeof this.form[key].value === 'object' && this.form[key].required === true && !this.form[key].value[0]) {
                fieldsError.push(`${key}_0`)
            }
        })
        return fieldsError
    }

    checkSetupFields = () => {
        let fieldsError = [];
        this.form.setupPlace.map(place => {
            place.blocks.map(block => {
                block.fields.map(field => {
                    if (field.fieldValue === '' && field.required === true) {
                        fieldsError.push(place.code + '_' + block.source + '_' + field.fieldName)
                    }
                })
            })
        })
        return fieldsError;
    }

    checkPrimaryFields = () => {
        let fieldsError = [];
        Object.keys(this.form).map(key => {
            if (typeof this.form[key].value === 'string' && this.form[key].value === "" && this.form[key].required === true) {
                this.form[key].isValid = false;
                fieldsError.push(key)
            }
        })
        return fieldsError;
    }

    getCurrentSetup = (code) => {
        return this.form.setupPlace.find(setup => {
            if (setup.code === code) return setup.blocks;
        });
    }

    @action setValidate = (fieldName, valid) => {
        if (this.form.hasOwnProperty(fieldName)) {
            this.form[fieldName].isValid = valid;
        }
    }


    //TODO переименовать в saveFieldValueToStore
    @action setFormFieldValue = (fieldName, fieldValue) => {
        if (this.form.hasOwnProperty(fieldName)) {
            this.form[fieldName].value = fieldValue;
        }
    }

    /**
     * выбирает из блока children ид потомков
     * @param blockArr
     * @returns {*[]}
     */
    getChildId(blockArr = []) {
        let outputArr = []
        blockArr.map(block => {
            if (block.children?.length) {
                block.children.map(child => {
                    outputArr.push(child.id)
                })
            }
        })
        return outputArr;
    }

    /**
     *
     * @param block обьект setupPlace.code['legalInfoSetup']
     * @param ids (массив ид кототорые нужно удалить из блока)
     * @returns {{}}
     */
    removeChild(block = {}, ids = []) {
        if (block.fieldValue.length) {
            let key;
            block.fieldValue.forEach((value, index) => {
                if (ids.includes(value.id)) {
                    key = index
                }
            })
            block.fieldValue.splice(key, 1);
        }
        return block;
    }

    @action removeDataFromField = (data) => {
        const idx = this.getChildId(data);
        const code = 'legalInfoSetup'
        const setup = this.form.setupPlace.find(setup => setup.code === code);

        if (setup) {
            setup.blocks.map(block => {
                block.fields.map((field) => {
                    this.removeChild(field, idx)
                })
            })
        }
    }

    @action moveDataInField = (source, code) => {

        if (this.mainCategoryList.length) {
            let outputArray = [];
            this.mainCategoryList.map((category) => {
                category.children.map(child => {
                    const tmp = { id: child.id, title: child.title, code: child.type.code }

                    let found = false;
                    outputArray.map(outItem => {
                        if (outItem.code === child.type.code) {
                            outItem.data.push(tmp)
                            found = true;
                            return 0;
                        }
                    })

                    if (!found) {
                        outputArray.push({ code: child.type.code, data: [tmp] });
                    }

                })
            })

            outputArray.map(item => {
                if (source === item.code) {
                    this.setSetupFieldValue(code, item.code, 'categoryList', item.data)
                }
            })
        }
    }

    getUniqCategory(dest, src) {
        if (!dest) return src;
        src.map(item => {
            if (!dest.find(element => element.id === item.id)) {
                dest.push(item);
            }
        })
        return dest;
    }


//TODO переименовать saveSetupFieldValueToSore
    /*
        @action setSetupFieldValue = (code, fieldSource, fieldName, fieldValue) => {
            const setup = this.form.setupPlace.find(setup => setup.code === code);
            if (setup) {
                const block = setup.blocks.find(block => block.source === fieldSource);
                block.fields.map((field, index) => {

                    if (field.fieldName === fieldName) {
                        const newField = this.getUniqCategory(block.fields[index].fieldValue, fieldValue)
                        block.fields[index] = {
                            ...field,
                            ...{ fieldValue: newField }
                        }
                    }
                })
            }
        }
    */


    @action setSetupFieldValue = (code, fieldSource, fieldName, fieldValue) => {
        const setup = this.form.setupPlace.find(setup => setup.code === code);
        if (setup) {
            const block = setup.blocks.find(block => block.source === fieldSource);
            block.fields.map((field, index) => {
                if (field.fieldName === fieldName) {
                    block.fields[index] = {
                        ...field,
                        ...{ fieldValue: fieldValue }
                    }
                }
            })
        }
    }


    @action setFromParentSetupFieldValue = (code, source, parentFieldName) => {
        if (this.form.hasOwnProperty(parentFieldName)) {


            let outputArray = [];
            this.form[parentFieldName].value.map(item => {
                item.children.map(child => {
                    const tmp = { id: child.id, title: child.title, code: child.type.code }

                    let found = false;
                    outputArray.map(outItem => {
                        if (outItem.code === child.type.code) {
                            outItem.data.push(tmp)
                            found = true;
                            return 0;
                        }
                    })

                    if (!found) {
                        outputArray.push({ code: child.type.code, data: [tmp] });
                    }

                })
            })
            outputArray.map(item => {
                if (source === item.code) {
                    this.setSetupFieldValue(code, item.code, 'categoryList', item.data)
                }
            })
        }

    }

    @action getSetupFieldValue = (code, source) => {
        const place = this.form.setupPlace.find(item => item.code === code)
        let value = [];
        place.blocks.map(block => {
            if (block.fields[0].fieldValue !== '') {
                block.fields[0].fieldValue.map(field => {
                    if (field.code === source) {
                        value.push({ 'id': field.id, 'title': field.title, code: field.code });
                    }
                });
            }
        })
        return value
    }

    /*
        @action getFormFieldValue = (fieldName) => {

            if (this.form.hasOwnProperty(fieldName)) {
                this.form[fieldName].value.map(val => {
                    if(val.hasOwnProperty('children')) {

                        val.children.map(child => {
                            this.parentCategoryList.push({'id': child.id, 'title': child.title, code: child.type.code})
                        })
                    }
                })
                return this.form[fieldName].value;
            }
        }
    */

    @action pushSetupPlaceValue = (code, fieldValue) => {
        const setup = this.form.setupPlace.find(setup => setup.code === code);

        if (setup) {
            setup.blocks.push(fieldValue)
        }
    }

    @action removeSetupPlace = (code = "") => {
        const setup = this.form.setupPlace.find(setup => setup.code === code);

        if (setup) {
            setup.blocks = []
        }
    }

    @action removeSetupPlaceItem = (code = "", source = "") => {
        const setup = this.form.setupPlace.find(setup => setup.code === code);

        if (setup) {
            const index = setup.blocks.findIndex(place => place.source === source)
            if (index >= 0) {
                setup.blocks.splice(index, 1);

                if (setup.blocks.length) {
                    setup.blocks[0].active = true;
                }
            }
        }
    }
}


class GeoDataStore {

    #geoDataService;

    @observable companyList = [];

    @observable filter = {
        title: '',
        page: {
            size: 10,
            number: 1,
        },
        sort: {
            column: "title",
            order: "asc",
        }
    }

    constructor(geoDataService) {
        this.#geoDataService = geoDataService;
    }


    getCompanyList() {
        return this.#geoDataService.getCompanyList()
            .then(result => {
                // тут результат привести к модели geoDataCompany (еще не готово)
                return result
            })
    }
}

const geoDataStore = new GeoDataStore(geoDataService)
const geoStore = new GeoStore();

export { geoStore, geoDataStore }
