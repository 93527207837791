import cn from "classnames";
import {Checkbox} from "primereact/checkbox";
import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";

const PaymentMethods = inject('geoStore')(observer(({geoStore}) => {

        const [paymentTypes, setPaymentTypes] = useState([])

        useEffect(() => {
            setPaymentTypes(geoStore.modelPayment);
        }, [])


        useEffect(() => {
            geoStore.setFormFieldValue('paymentTypes', paymentTypes)
        }, [paymentTypes])


        const onChecked = (code) => {
            let copy = [...paymentTypes];
            copy.map(payment => {
                if (payment.code === code) {
                    payment.active = !payment.active
                }
            })
            setPaymentTypes(copy)
        }

        return (
            <>
                <div className={cn('block')}>
                    <div className={cn('block__title')}>
                        <h3>Способы оплаты</h3>
                    </div>
                    <div className={cn('block__form')}>
                        <div className={cn("form__row")}>
                            <ul className={cn("checkbox-field")}>
                                {paymentTypes.map((type, key) => (
                                    <Payment
                                        item={type}
                                        key={key}
                                        onChecked={onChecked}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }
))

const Payment = ({item, onChecked}) => {


    return (
        <li>
            <Checkbox
                inputId={item.code}
                value={item.code}
                checked={item.active}
                onChange={() => {
                    onChecked(item.code)
                }}
            ></Checkbox>
            <label
                htmlFor={item.code}
                className="p-checkbox-label">
                {item.title}
            </label>
        </li>
    )
}

export default PaymentMethods;