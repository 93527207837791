import React, {useEffect, useState} from "react";
import cn from "classnames";
import {inject, observer} from "mobx-react";
import NameCompany from "./bloks/NameCompany";
//import {reaction, toJS} from "mobx";
import "../Company.scss";
import Logo from "./bloks/Logo";
import LegalInfo from "./bloks/LegalInfo";
import {ContactInfo} from "./bloks/ContactInfo";
import BrandBranch from "./bloks/BrandBranch";
import AddressCompany from "./bloks/AddressCompany";
import {SocialMedia} from "./bloks/SocialMedia";
import WorkTime from "./bloks/WorkTime";
import PaymentMethods from "./bloks/PaymentMethods";
import Description from "./bloks/Description";
import FloatMenu from "./bloks/FloatMenu";
import NavigateBtnUp from "components/pages/application/common/NavigateBtnUp";
import {Button} from "primereact/button";
import {scroller} from "react-scroll";
import Category from "./bloks/Category";

const Company = ({geoStore}) => {
        const {form} = geoStore;

/*
        reaction(
            () => JSON.stringify(form.setupPlace),
            (form) => {
                console.log(form);
            }
        );
*/

    const [showFloatMenu, setShowFloatMenu] = useState(false)
    const [showButtonUp, setShowButtonUp] = useState(false)

    const checkRequiredFields = () => {
        const fieldsError = [
            ...geoStore.checkPrimaryFields(),
            ...geoStore.checkArrayField(),
            ...geoStore.checkSetupFields(),
        ]

//todo переписать на ref
        if (fieldsError.length > 0) {
            fieldsError.map(field => {
                const element = document.getElementById(field);
                if (element) {
                    element.classList.add('p-invalid')
                }
            })

            const element = document.getElementsByClassName('p-invalid')

            if (element[0]) {
                scroller.scrollTo(element[0].id, {
                    offset: -180,
                    duration: 500,
                    delay: 0,
                    smooth: 'easeInOutQuart'
                });
            }
        }
    }

    useEffect(() => {
        const scrollListener = () => {
            const scrollTop = (window.pageYOffset || document.documentElement.scrollTop);

            if (scrollTop >= 100) {
                setShowFloatMenu(true);
                setShowButtonUp(true);
            } else {
                setShowFloatMenu(false);
                setShowButtonUp(false);
            }
        }

        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        }
    }, [])

    return (

        <>
            <div className={cn('page__company-edit', 'page')}>
                <div className={'company-edit'}>
                    {showFloatMenu && <FloatMenu checkRequiredFields={checkRequiredFields}/>}
                    <div className={cn('title')}>
                        <h1>Редактирование компаний</h1>
                    </div>
                    <div className={cn('company-form__blocks')}>
                        {/*Название*/}
                        <div className={cn('block__wrapper')} id={'NameCompany'}>
                            <NameCompany/>
                        </div>

                        {/*Бренд и код филиала*/}
                        <div className={cn('block__wrapper')} id={'BrandBranch'}>
                            <BrandBranch/>
                        </div>

                        {/*Адрес*/}
                        <div className={cn('block__wrapper')} id={'AddressCompany'}>
                            <AddressCompany/>
                        </div>

                        {/*Юридическая информация*/}
                        <div className={cn('block__wrapper')} id={'LegalInfo'}>
                            <LegalInfo/>
                        </div>

                        {/*Категории*/}
                        <div className={cn('block__wrapper')} id={'Category'}>
                            <Category/>
                        </div>


                        {/*Контактная информация*/}
                        <div className={cn('block__wrapper')} id={'ContactInfo'}>
                            <ContactInfo/>
                        </div>


                        {/*Сайт и ссылки на социальные сети*/}
                        <div className={cn('block__wrapper')} id={'SocialMedia'}>
                            <SocialMedia/>
                        </div>


                        {/*Время работы и временные изменения в режиме*/}
                        <div className={cn('block__wrapper')} id={'WorkTime'}>
                            <WorkTime/>
                        </div>


                        {/*Способы оплаты*/}
                        <div className={cn('block__wrapper')} id={'PaymentMethods'}>
                            <PaymentMethods/>
                        </div>


                        {/*Описание*/}
                        <div className={cn('block__wrapper')} id={'Description'}>
                            <Description/>
                        </div>


                        {/*Логотип и обложка*/}
                        <div className={cn('block__wrapper')} id={'Logo'}>
                            <Logo/>
                        </div>

                        <div className={cn('block__wrapper', 'pt-0')}>
                            <div className={cn('block')}>
                                <div className={cn('block__form')}>
                                    <div className={cn("form__row")}>
                                        <Button
                                            label='Сохранить'
                                            onClick={() => {
                                                checkRequiredFields()
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showButtonUp && <NavigateBtnUp />}
                    </div>

                </div>
            </div>
        </>
    )

}

export default inject('geoStore')(observer(Company));