import React, {useEffect, useRef, useState} from "react";
import "./Booklet.scss";
import cn from "classnames";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import Booklet1 from "../../../images/booklet1.png";
import Booklet2 from "../../../images/booklet2.png";
import Booklet3 from "../../../images/booklet3.png";
import Booklet4 from "../../../images/booklet4.png";
import Booklet5 from "../../../images/booklet5.png";
import {PDFDocument} from "pdf-lib";
import QRCode from "qrcode";
import {PDF_DOWNLOAD} from "models/pdf-template";
import {useParams} from "react-router";
import {inject, observer} from "mobx-react";

//TODO все возможные названия шаблонов (убрать куда нибудь в конфиг)
const TEMPLATES_CODE = ['ao_medicina'];

async function modifyPdf(pdfFile = null, qrCode = null, params = {}) {
    if ((pdfFile && qrCode) === null) return;

    const existingPdfBytes = await fetch(pdfFile).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const pngImage = await pdfDoc.embedPng(qrCode)
    const page = pdfDoc.getPages()[0]

    page.drawImage(pngImage, {
        x: params.xOffset,
        y: page.getHeight() - params.yOffset,
        width: params.width,
        height: params.height,
    })

    if (params.xOffsetDouble?.length > 0) {
        params.xOffsetDouble.map(offset => {
            page.drawImage(pngImage, {
                x: params.xOffset + (offset),
                y: page.getHeight() - params.yOffset,
                width: params.width,
                height: params.height,
            })
        })
    }

    return await pdfDoc.save()
}

const downloadPdf = (pdfBytes, fileName = 'test') => {
    const objURL = window.URL.createObjectURL(
        new Blob([pdfBytes], {type: "application/pdf"})
    );

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = objURL;
    a.setAttribute("download", fileName);
    a.click();
    document.body.removeChild(a);
}

const QRGenerate = (text = null, fileName = 'pdf_file', pdfTemplate = null, params = {}) => {
    if ((text && pdfTemplate) === null) return;

    QRCode.toDataURL(text, {quality: 1, margin: 0})
        .then(qrCode => {
            modifyPdf(pdfTemplate, qrCode, params).then(pdfResult => {
                downloadPdf(pdfResult, fileName);
            })
        })
        .catch(err => {
            console.error(err)
        })
}

const Booklet = ({userStore}) => {
    const {id} = useParams();
    const [utm, setUtm] = useState('');
    const qrContRef = useRef(null);


    const findBranch = (id) => {
        const {branches} = userStore.profile.roles[0];
        return branches.find(b => b.id === +id) || null;
    }
    const branch = findBranch(id);

    useEffect(() => {

            if (
                userStore.profile.client.reviewCollectorTemplate
                && TEMPLATES_CODE.includes(userStore.profile.client.reviewCollectorTemplate)
            ) {
                setUtm(`${process.env.REACT_APP_reviewUrl}${userStore.profile.client.reviewCollectorTemplate}/qr/${branch?.qrid}`)
            } else {
                setUtm(`${process.env.REACT_APP_reviewUrl}qr/${branch?.qrid}`)
            }
    }, [branch])

    useEffect(() => {

        if (qrContRef.current !== null) {
            QRCode.toCanvas(utm, function (err, canvas) {
                if (!err) {
                    if (err) throw err
                    let cont = qrContRef.current;
                    cont.appendChild(canvas)
                }
            })
        }
    }, [utm])

    return (
        <div className={cn('page__booklet-list', 'page')}>
            <div className={'booklet-list'}>
                <>
                    <div className={'title'}>
                        <Link to='/integration' className="p-button p-component back-btn">
                            <i className="pi pi-angle-left"></i>
                        </Link>
                        <h1>Печать материалов с QR-кодом</h1>
                    </div>
                    <div className={cn('title__sub')}>{branch && branch.title}</div>
                    {utm && <div className={cn('booklet-list__table')}>
                        {/*Визитка*/}
                        <div className={cn('booklet-item')}>
                            <div className={cn('image')}>
                                <img src={Booklet1} alt="" width={340}/>
                            </div>

                            <div className={cn('header')}>Визитка</div>
                            <div className={cn('content')}>
                                На одной стороне могут быть напечатаны контакты менеджера и логотип филиала. На другой
                                стороне — QR-код и предложение оставить отзыв. Прикрепляйте такие визитки к чекам.
                            </div>
                            <div className={cn('footer')}>
                                <DownloadPDF
                                    utm={utm}
                                    onlyDownload={true}
                                    templates={
                                        [
                                            PDF_DOWNLOAD.CART.gray.templates,
                                        ]
                                    }
                                    params={
                                        {
                                            QrPositionGray: PDF_DOWNLOAD.CART.gray.position,
                                        }
                                    }
                                />
                            </div>
                        </div>
                        {/*Распечатка А4*/}
                        <div className={cn('booklet-item')}>
                            <div className={cn('image')}>
                                <img src={Booklet2} alt="" width={340}/>
                            </div>

                            <div className={cn('header')}>Распечатка А4</div>
                            <div className={cn('content')}>
                                Поставьте на стойку ресепшн или кассы. Напоминайте о QR-коде довольным клиентам, чтобы
                                они не забывали оставлять отзыв.
                            </div>
                            <div className={cn('footer')}>
                                <DownloadPDF
                                    templates={
                                        [
                                            PDF_DOWNLOAD.A4.gray.templates,
                                            PDF_DOWNLOAD.A4.color.templates
                                        ]
                                    }
                                    utm={utm}
                                    params={
                                        {
                                            QrPositionGray: PDF_DOWNLOAD.A4.gray.position,
                                            QrPositionColor: PDF_DOWNLOAD.A4.color.position,
                                        }
                                    }
                                />
                            </div>
                        </div>
                        {/*Распечатка А5*/}
                        <div className={cn('booklet-item')}>
                            <div className={cn('image')}>
                                <img src={Booklet3} alt="" width={340}/>
                            </div>

                            <div className={cn('header')}>Распечатка А5</div>
                            <div className={cn('content')}>
                                Баннер для стойки ресепшн меньшего размера. Можно распечатать 2 баннера на одном листе
                                А4 и разрезать пополам.
                            </div>
                            <div className={cn('footer')}>
                                <DownloadPDF
                                    templates={
                                        [
                                            PDF_DOWNLOAD.A5.gray.templates,
                                            PDF_DOWNLOAD.A5.color.templates
                                        ]
                                    }
                                    utm={utm}
                                    params={
                                        {
                                            QrPositionGray: PDF_DOWNLOAD.A5.gray.position,
                                            QrPositionColor: PDF_DOWNLOAD.A5.color.position,
                                        }
                                    }
                                />
                            </div>
                        </div>
                        {/*Пирамидка*/}
                        <div className={cn('booklet-item')}>
                            <div className={cn('image')}>
                                <img src={Booklet4} alt="" width={340}/>
                            </div>
                            <div className={cn('header')}>Пирамидка</div>
                            <div className={cn('content')}>
                                Для самостоятельной печати на листе А4. Распечатанную схему нужно согнуть в трех местах
                                и проклеить в месте склейки. Лучше использовать плотную бумагу.
                            </div>
                            <div className={cn('footer')}>
                                <DownloadPDF
                                    templates={
                                        [
                                            PDF_DOWNLOAD.PYRAMID.gray.templates,
                                            PDF_DOWNLOAD.PYRAMID.color.templates
                                        ]
                                    }
                                    utm={utm}
                                    params={
                                        {
                                            QrPositionGray: PDF_DOWNLOAD.PYRAMID.gray.position,
                                            QrPositionColor: PDF_DOWNLOAD.PYRAMID.color.position,
                                        }
                                    }
                                />
                            </div>
                        </div>
                        {/*Баннер для экранов*/}
                        <div className={cn('booklet-item')}>
                            <div className={cn('image')}>
                                <img src={Booklet5} alt="" width={340}/>
                            </div>
                            <div className={cn('header')}>Баннер для экранов</div>
                            <div className={cn('content')}>
                                Вариант для торговых залов с большим монитором. Включите баннер в свой видеоряд, чтобы
                                напоминать клиентам об отзывах.
                            </div>
                            <div className={cn('footer')}>
                                <DownloadPDF
                                    onlyDownload={true}
                                    templates={
                                        [
                                            PDF_DOWNLOAD.MONITOR.gray.templates,
                                        ]
                                    }
                                    utm={utm}
                                    params={
                                        {
                                            QrPositionGray: PDF_DOWNLOAD.MONITOR.gray.position,
                                        }
                                    }
                                />
                            </div>
                        </div>
                        {/*чистый qrcode*/}
                        <div className={cn('booklet-item')}>
                            <div className={cn('image')}>
                                <div ref={qrContRef}></div>
                            </div>
                            <div className={cn('header')}>QR для своего дизайна</div>
                            <div className={cn('content')}>
                                Скачать SVG файл для интеграции в собственный дизайн.
                            </div>
                            <div className={cn('footer')}>
                                <DownloadQR
                                    utm={utm}
                                />
                            </div>
                        </div>
                    </div>}
                </>
            </div>
        </div>
    );
};


const DownloadQR = ({utm}) => {
    const handlerDownload = () => {

        QRCode.toString(utm, {quality: 1, margin: 0, type: 'svg'})
            .then(qrCode => {
                const base64doc = btoa(unescape(encodeURIComponent(qrCode)));
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = 'data:text/html;base64,' + base64doc;
                a.setAttribute("download", 'qrcode.svg');
                a.click();
                document.body.removeChild(a);
            })
            .catch(err => {
                console.error(err)
            })
    }

    return (
        <>
            <Button
                label='Скачать'
                className={cn('p-button')}
                onClick={() => {
                    handlerDownload()
                }}

            />

        </>
    )
}

const DownloadPDF = ({onlyDownload = false, templates = [], utm, params = {}}) => {

    const handlerDownload = (template, position) => {
        if (template <= 0) return false;
        QRGenerate(utm, 'pdf_file_name', template, position);
    }

    return (
        <>
            {!onlyDownload ? <>
                    {params.QrPositionGray && <Button
                        label='Для ч/б печати'
                        className={cn('p-button')}
                        onClick={() => {
                            handlerDownload(templates[0], params.QrPositionGray)
                        }}
                    />}
                    {params.QrPositionColor && <Button
                        label='Для печати в цвете'
                        className={cn('p-button-outlined')}
                        onClick={() => {
                            handlerDownload(templates[1], params.QrPositionColor)
                        }}
                    />}
                </>
                :
                <Button
                    label='Скачать'
                    className={cn('p-button')}
                    onClick={() => {
                        handlerDownload(templates[0], params.QrPositionGray)
                    }}

                />
            }
        </>
    )
}


export default inject('userStore')(observer(Booklet));
