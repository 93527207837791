import React, { useState} from "react";
import cn from "classnames";
import Setup from "../Setup";
import {TopFormFields} from "../../../../../stores/forms/topForm.store";
import {ContactPhoneSingle} from "./ContactPhone";
import {ContactEmailSingle} from "./ContactEmail";


const ContactInfo = () => {

    const [contactPhoneSingleValue, setContactPhoneSingleValue] = useState('');

    const ContactPhoneOnChange = (value) => {
        setContactPhoneSingleValue(value[0])
    }

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Контактная информация</h3>
                </div>
                <div className={cn('block__form')}>
                    <ContactPhoneSingle
                        fieldName={'fieldName15'}
                        onChange={ContactPhoneOnChange}
                    />
                    <ContactEmailSingle
                        fieldName={'fieldName16'}
                    />
                    <div className={cn("form__row")}>
                        <div className={cn('title-field')}>
                            <h4>Настройка площадок</h4>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <Setup
                            disabled={!contactPhoneSingleValue.length}
                            fields={
                                [
                                    new TopFormFields({
                                        fieldName: 'phone',
                                        fieldLabel: 'Номер телефона',
                                        fieldType: 'dynamic-text',
                                        fieldValue: contactPhoneSingleValue,
                                        required: false
                                    }),
                                    new TopFormFields({
                                        fieldName: 'email',
                                        fieldLabel: 'Email адрес',
                                        fieldType: 'dynamic-text',
                                        fieldValue: '',
                                        required: false
                                    }),

                                ]
                            }
                            code={"contactInfoSetup"}
                        />
                    </div>

                </div>
            </div>
        </>
    )
}

export {
    ContactInfo,
};