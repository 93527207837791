import React from "react";
import cn from "classnames";
import {inject, observer} from "mobx-react";
import OkInputText from "../../../../common/OkInputText";

const BrandBranch = ({geoStore}) => {

    const {form} = geoStore;

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Бренд и код филиала</h3>
                </div>
                <div className={cn('block__form')}>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='fieldName3'
                                    type="text"
                                    name='fieldName3'
                                    required={form.fieldName3.required}
                                    errortext={'Поле обязательно для заполнения'}
                                    value={form.fieldName3.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName3', e.target.value)
                                    }}
                                />
                                <label htmlFor='fieldName3'>Код филиала
                                    {form.fieldName3.required && <span>*</span>}
                                </label>
                            </div>
                        </div>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='fieldName4'
                                    type="text"
                                    name='fieldName4'
                                    required={form.fieldName4.required}
                                    errortext={'Поле обязательно для заполнения'}
                                    value={form.fieldName4.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName4', e.target.value)
                                    }}
                                />
                                <label htmlFor='fieldName4'>Бренд
                                    {form.fieldName4.required && <span>*</span>}
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default inject('geoStore')(observer(BrandBranch))