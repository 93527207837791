import authService from "../auth";
import { serialize } from "../../helpers";

class GeoData {
    getRubricsCompound = (keyword) => {
        return authService.get(
            `/geodata/rubrics/compound?${serialize({ query: keyword })}`,
        ).then((result) => {
            return result;
        });
    }

    getRubricsAdplatform = (keyword, code) => {
        return authService.get(
            `/geodata/rubrics/adplatform/${code}?${serialize({ query: keyword })}`,
        ).then((result) => {
            return result;
        });
    }

    getCompanyList = ($filter) => {
        return authService.get(
            `geodata/companies?${serialize($filter)}`,
        ).then((result) => {
            return result;
        });

    }

}

const geoDataService = new GeoData();
export default geoDataService;