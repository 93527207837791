import React from "react";
import cn from "classnames";
import OkUpload from "./demo/OkUpload";

const Logo = () => {

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Логотип и обложка</h3>
                </div>
                <div className={cn('block__form')}>
                    <div className={cn("form__row")}>
                        <div className={cn("upload-filed")}>
                            <span className={cn("upload-filed__title")}>Логотип</span>
                            <div className={cn("upload-filed__place")}>
                                <OkUpload
                                    aspect={1}
                                    className={'upload_logo'}
                                    size={{width:146, height:146}}
                                />
                            </div>
                        </div>
                        <div className={cn("upload-filed")}>
                            <span className={cn("upload-filed__title")}>Обложка</span>
                            <div className={cn("upload-filed__place", "upload-filed__place--width-540")}>
                                <OkUpload
                                    aspect={4.03}
                                    className={'upload_cover'}
                                    size={{width:540, height:146}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Logo