export default [
    {
        key: 'NameCompany',
        href: '#NameCompany',
        title: 'Название',
    },
    {
        key: 'BrandBranch',
        href: '#BrandBranch',
        title: 'Бренд и код',
    },
    {
        key: 'AddressCompany',
        href: '#AddressCompany',
        title: 'Адрес',
    },
    {
        key: 'LegalInfo',
        href: '#LegalInfo',
        title: 'Юр. инфо.',
    },
    {
        key: 'Category',
        href: '#Category',
        title: 'Категории',
    },

    {
        key: 'ContactInfo',
        href: '#ContactInfo',
        title: 'Контакты',
    },
    {
        key: 'SocialMedia',
        href: '#SocialMedia',
        title: 'Сайт и соц. сети',
    },
    {
        key: 'WorkTime',
        href: '#WorkTime',
        title: 'Время работы',
    },
    {
        key: 'PaymentMethods',
        href: '#PaymentMethods',
        title: 'Способы оплаты',
    },
    {
        key: 'Description',
        href: '#Description',
        title: 'Описание',
    },
    {
        key: 'Logo',
        href: '#Logo',
        title: 'Лого',
    },

]