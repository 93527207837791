import React, { useEffect, useState } from "react";
import cn from "classnames";
import _ from "underscore";
import { inject, observer } from "mobx-react";
import geoDataService from "../../../../../services/geo/geo-category";
import { AutoComplete } from "antd";
import { reaction } from "mobx";

//TODO отрефачить эту кашу!!!!

const LegalInfoCategorySingle = inject('geoStore')(observer((props) => {
    const { geoStore, name, onChange } = props;

    useEffect(()=>{
    }, []);

    const saveCategoryList = (categoryList) => {
        onChange(categoryList)
        geoStore.setFormFieldValue(name, categoryList);
        geoStore.saveInMainCategoryList(categoryList);
    }

    const singleProps = {
        ...props,
        ...{ saveCategoryList, geoStore },
    }

    return (
        <LegalInfoCategory {...singleProps} />
    )
}));


const LegalInfoCategoryMulti = inject('geoStore')(observer((props) => {
    const { geoStore, code, source, name, parentFieldName } = props;
    const [parentCategoryList, SetParentCategoryList] = useState(null)

    useEffect(() => {
        geoStore.setFromParentSetupFieldValue(code, source, parentFieldName)
        SetParentCategoryList(geoStore.getSetupFieldValue(code, source))
    }, [])

    const saveCategoryList = (categoryList) => {
        geoStore.setSetupFieldValue(code, source, name, categoryList)
    }

    const multiProps = {
        ...props,
        ...{ saveCategoryList, geoStore },
        ...{ parentCategoryList: parentCategoryList },
    }
    return (
        <>{parentCategoryList !== null && <LegalInfoCategory {...multiProps} />}</>
    )
}))

const LegalInfoCategory = (props) => {
    const { name = '', saveCategoryList, parentCategoryList = [], geoStore } = props;

    const [categoryList, setCategoryList] = useState([]);
    const [options, setOptions] = useState([]);
    const [fieldName, setFieldName] = useState('');
    const [value, setValue] = useState('');

    const getRubricsCompound = _.debounce(
        (value) => {
            if (value.length >= 1) {
                geoDataService.getRubricsCompound(value).then(result => {
                    setOptions(result.items.map(res => {
                        return { value: res.title, label: res.title, object: {id: res.id, title: res.title, code: res.type?.code} }
                    }))
                })
            }
            else {
                setOptions([])
            }
        },
        800
    );

    const getRubricsAdplatform = _.debounce(
        (value, code) => {
            if (value.length >= 1) {
                geoDataService.getRubricsAdplatform(value, code).then(result => {
                    setOptions(result.items.map(res => {
                        return { value: res.title, label: res.title, object: {id: res.id, title: res.title, code: res.type.code}}
                    }))
                })
            }
            else {
                setOptions([])
            }

        },
        800
    );

    useEffect(() => {
        reaction(
            () => JSON.stringify(props.geoStore.mainCategoryList),
            () => {
                if(props.code && props.source) {
                    geoStore.moveDataInField(props.source, props.code)
                    setCategoryList(geoStore.getSetupFieldValue(props.code, props.source))
                }
            }
        );

        setFieldName(name)
        setCategoryList(parentCategoryList);
    }, []);


    useEffect(() => {
        saveCategoryList(categoryList)
    }, [categoryList])

    const onDeleteCategory = (key) => {
        let deleteItem;
        let copy = [
            ...[],
            ...categoryList
        ];

        if (key > -1) {
            deleteItem = copy.splice(key, 1);
        }

        geoStore.removeDataFromField(deleteItem)
        setCategoryList(copy);
    }

    const handleKeyPress = (ev) => {
        setValue(ev.target.value)
    };

    const onSelect = (value, result) => {
        const list = [
            ...categoryList,
            ...[result.object]
        ];
        setCategoryList(list);
        setValue('')
        setOptions([])
    };

    const handleSearch = (value) => {
        if(props.source) {
            getRubricsAdplatform(value, props.source)
        }
        else {
            getRubricsCompound(value)
        }

    };

    return (
        <>
            <div className={cn("form__row")}>
                <div className={cn('text-field', 'text-field--width-580')}>
                    {fieldName !== '' && <div className={cn("p-float-label")}>
                        <AutoComplete
                            options={options}
                            style={{
                                width: '100%'
                            }}
                            id={fieldName}
                            name={fieldName}
                            onSelect={onSelect}
                            onSearch={handleSearch}
                            onKeyPress={handleKeyPress}
                            onChange={(value)=>{
                                setValue(value)
                            }}
                            value={value}
                            placeholder={'Добавление категорий (начните вводить)'}
                        >
                            <input type="text" className={cn('p-inputtext p-component')}/>
                        </AutoComplete>
                    </div>}
                </div>
                <div className={cn('description-field')}>
                    <p>Соответствующие категории для каталога будут подобраны автоматически</p>
                </div>

            </div>
            {/*
            parentCategoryList: {JSON.stringify(props.geoStore.parentCategoryList)}
            <br/>
            categoryList: {JSON.stringify(categoryList)}
*/}
            <div className={cn("form__row")}>
                <div className={cn('pills-field')}>
                    {categoryList.map((item, key) => (
                        <Pill
                            key={key}
                            onDelete={onDeleteCategory}
                            index={key}
                        >{item.title}</Pill>
                    ))}
                </div>
            </div>
        </>
    )
};

const Pill = ({ onDelete, children, index }) => {
    return (
        <>
            <span className={cn("pills")}>
                {children}
                <i className={cn("pi", "pi-times")}
                   onClick={() => onDelete(index)}
                ></i>
            </span>
        </>
    )
}

export {
    LegalInfoCategorySingle,
    LegalInfoCategoryMulti,
}
