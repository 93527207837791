import React from "react";
import {Modal, Upload} from 'antd';

import ImgCrop from 'antd-img-crop';
import {useState} from 'react';
import cn from "classnames";
import "./OkUpload.scss";

const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
    });
};

const OkUpload = ({aspect, className, size}) => {
    const [fileList, setFileList] = useState([]);

    const onChange = ({fileList: newFileList}) => {
        setFileList(newFileList);
    };

    const onPreview = async (file) => {
        const src = file.url || (await getSrcFromFile(file));
        const imgWindow = window.open(src);

        if (imgWindow) {
            const image = new Image();
            image.src = src;
            imgWindow.document.write(image.outerHTML);
        } else {
            window.location.href = src;
        }
    };

    const setPreviewFile = (file, size) => {
        const {width, height} = size;

        return new Promise(resolve => {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            canvas.style.cssText = `position: fixed; left: 0; top: 0; width: ${width}px; height: ${height}px; z-index: 9999; display: none;`;
            document.body.appendChild(canvas);
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.onload = () => {
                ctx.drawImage(img, 0, 0, width, height);
                const dataURL = canvas.toDataURL();
                document.body.removeChild(canvas);
                resolve(dataURL);
            };
            img.src = window.URL.createObjectURL(file);
        });
    }

    const onRemove = () => {
        const {confirm} = Modal;

        return new Promise((resolve, reject) => {
            confirm({
                title: 'Удалить изображение?',
                onOk: () => {
                    resolve(true)
                },
                onCancel: () => {
                    reject(true)
                }
            })
        })
    }

    return (
        <>
            <ImgCrop
                zoomSlider
                aspect={aspect}
                modalTitle={'Редактирование'}
                modalOk={'Сохранить'}
                modalCancel={'Отменить'}
            >
                <Upload
                    className={cn(className)}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    maxCount={1}
                    onRemove={onRemove}
                    onPreview={onPreview}
                    previewFile={(file) => {
                        return setPreviewFile(file, size);
                    }}
                    customRequest={({onSuccess}) => {
                        setTimeout(() => {
                            onSuccess("ok");
                        }, 0);
                    }}
                >
                    {fileList.length < 1 && '+ Загрузить'}
                </Upload>
            </ImgCrop>

        </>
    );

};

export default OkUpload