import React from "react";
import cn from "classnames";
import { Dropdown } from 'primereact/dropdown';
import { inject, observer } from "mobx-react";
import OkInputText from "../../../../common/OkInputText";

const LegalInfo = ({ geoStore }) => {
    const { form } = geoStore;

    const list = [
        { name: 'ООО', code: 1 },
        { name: 'ФПГ', code: 2 },
        { name: 'ГБДОУ', code: 3 },
        { name: 'ЧУП', code: 4 },
        { name: 'ЧТУП', code: 5 },
        { name: 'ЧДТПУП', code: 6 },
    ];


    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Юридическая информация</h3>
                </div>
                <div className={cn('block__form')}>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <Dropdown
                                    optionLabel="name"
                                    optionValue="code"
                                    id='fieldName12'
                                    name='fieldName12'
                                    required={form.fieldName12.required}
                                    value={form.fieldName12.value}
                                    options={list}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName12', e.target.value)
                                    }}/>
                                <label htmlFor='fieldName12'>
                                    Организационно-правовая форма
                                    {form.fieldName12.required && <span>*</span>}
                                </label>
                            </div>
                        </div>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='fieldName13'
                                    type="text"
                                    name='fieldName13'
                                    required={form.fieldName13.required}
                                    errortext={'Поле обязательно для заполнения'}
                                    value={form.fieldName13.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName13', e.target.value)
                                    }}
                                />
                                <label htmlFor='fieldName13'>Юридическое название
                                    {form.fieldName13.required && <span>*</span>}
                                </label>
                            </div>
                        </div>
                        <div className={cn('description-field')}>
                            <p>Оба поля должны быть заполнены или остаться пустыми</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default inject('geoStore')(observer(LegalInfo));