import React, {useState} from "react";
import cn from "classnames";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {InputSwitch} from "primereact/inputswitch";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import {inject, observer} from "mobx-react";
import OkInputText from "../../../../common/OkInputText";

const AddressCompany = ({geoStore}) => {

    const {form} = geoStore;
    const [geometry, setGeometry] = useState([]);
    const [mapEnabled, setMapEnabled] = useState(false);

    const clickOnMap = (e) => {
        const coords = e.get('coords')
        geoStore.setFormFieldValue('coords_1', coords[0]);
        geoStore.setFormFieldValue('coords_2', coords[1]);
        setGeometry(coords)
    }

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Адрес</h3>
                </div>
                <div className={cn('block__form')}>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='fieldName5'
                                    type="text"
                                    name='fieldName5'
                                    value={form.fieldName5.value}
                                    required={form.fieldName5.required}
                                    errortext={'Поле обязательно для заполнения'}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName5', e.target.value)
                                    }}

                                />
                                <label htmlFor='fieldName5'>Страна
                                    {form.fieldName5.required && <span>*</span>}
                                </label>
                            </div>
                        </div>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='fieldName6'
                                    type="text"
                                    name="fieldName6"
                                    value={form.fieldName6.value}
                                    required={form.fieldName6.required}
                                    errortext={'Поле обязательно для заполнения'}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName6', e.target.value)
                                    }}

                                />
                                <label htmlFor='fieldName6'>Область
                                    {form.fieldName6.required && <span>*</span>}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='fieldName7'
                                    type="text"
                                    name='fieldName7'
                                    value={form.fieldName7.value}
                                    required={form.fieldName7.required}
                                    errortext={'Поле обязательно для заполнения'}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName7', e.target.value)
                                    }}

                                />
                                <label htmlFor='fieldName7'>Город
                                    {form.fieldName7.required && <span>*</span>}
                                </label>
                            </div>
                        </div>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='fieldName8'
                                    type="text"
                                    name='fieldName8'
                                    value={form.fieldName8.value}
                                    required={form.fieldName8.required}
                                    errortext={'Поле обязательно для заполнения'}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName8', e.target.value)
                                    }}

                                />
                                <label htmlFor='fieldName8'>Почтовый индекс
                                    {form.fieldName8.required && <span>*</span>}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='fieldName9'
                                    type="text"
                                    name='fieldName9'
                                    required={form.fieldName9.required}
                                    errortext={'Поле обязательно для заполнения'}
                                    value={form.fieldName9.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName9', e.target.value)
                                    }}

                                />
                                <label htmlFor='fieldName9'>Улица
                                    {form.fieldName9.required && <span>*</span>}
                                </label>
                            </div>
                        </div>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='fieldName10'
                                    type="text"
                                    name='fieldName10'
                                    required={form.fieldName10.required}
                                    errortext={'Поле обязательно для заполнения'}
                                    value={form.fieldName10.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName10', e.target.value)
                                    }}

                                />
                                <label htmlFor='fieldName10'>Номер дома
                                    {form.fieldName10.required && <span>*</span>}
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('textarea-field')}>
                            <div className={cn("p-float-label")}>
                                <InputTextarea id="fieldName11" name="fieldName10"/>
                                <label htmlFor='fieldName11'>Комментарий к адресу...</label>
                            </div>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn("input-switch")}>
                            <InputSwitch
                                checked={mapEnabled}
                                onChange={() => {
                                    setMapEnabled(!mapEnabled)
                                }}
                            />
                        </div>
                        <div
                            className={cn("input-switch-label")}
                            onClick={() => {
                                setMapEnabled(prev => !prev)
                            }}
                        >
                            Задать точные координаты на карте вручную
                        </div>
                    </div>
                    {mapEnabled && <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <InputText
                                    id='coords_1'
                                    type="text"
                                    name='coords_1'
                                    value={form.coords_1.value}
                                    readOnly
                                />
                                <label htmlFor='coords_1'>Широта</label>
                            </div>
                        </div>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <InputText
                                    id='coords_2'
                                    type="text"
                                    name='coords_2'
                                    value={form.coords_2.value}
                                    readOnly
                                />
                                <label htmlFor='coords_2'>Долгота</label>
                            </div>
                        </div>
                    </div>}
                    <div className={cn("form__row", "ya__map")}>
                        {!mapEnabled && <div className={cn("disabled")}></div>}
                        <YMaps>
                            <Map
                                className={cn("map-container")}
                                defaultState={{center: [55.75, 37.57], zoom: 11}}
                                onClick={(e) => clickOnMap(e)}
                            >
                                <Placemark
                                    geometry={geometry}
                                />
                            </Map>
                        </YMaps>
                    </div>
                </div>
            </div>
        </>
    )
}

export default inject('geoStore')(observer(AddressCompany))