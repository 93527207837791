import React from "react";
import cn from "classnames";
import {InputTextarea} from "primereact/inputtextarea";
import Setup from "../Setup";
import {TopFormFields} from "../../../../../stores/forms/topForm.store";
import {inject, observer} from "mobx-react";

const Description = inject('geoStore')(observer(({geoStore}) => {

    const {form} = geoStore;

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Описание</h3>
                </div>
                <div className={cn('block__form')}>
                    <div className={cn("form__row", "form__row_align-start")}>
                        <div className={cn('textarea-field')}>
                            <div className={cn("p-float-label")}>
                                <InputTextarea
                                    maxLength="200"
                                    id="fieldName19"
                                    name="fieldName19"
                                    required={form.fieldName19.required}
                                    value={form.fieldName19.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName19', e.target.value)
                                    }}
                                />
                                <label htmlFor='fieldName19'>Краткое описание компании <i className={'additional'}>(не более 200 символов)</i>
                                    {form.fieldName19.required && <span>*</span>}
                                </label>
                                <div className={cn('maxLength__wrapper')}>
                                    <span className={cn('maxLength')}>{form.fieldName19.value.length}/200</span>
                                </div>
                            </div>
                        </div>
                        <div className={cn('description-field')}>
                            <p>Каталоги имеют свои ограничения на длину краткого и полного описания.</p>
                        </div>

                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('textarea-field')}>
                            <div className={cn("p-float-label")}>
                                <InputTextarea
                                    maxLength="500"
                                    id="fieldName20"
                                    name="fieldName20"
                                    required={form.fieldName20.required}
                                    value={form.fieldName20.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('fieldName20', e.target.value)
                                    }}
                                />
                                <label htmlFor='fieldName20'>Полное описание описание компании <i className={'additional'}>(не более 500 символов)</i>
                                    {form.fieldName20.required && <span>*</span>}
                                </label>
                            </div>
                            <div className={cn('maxLength__wrapper')}>
                                <span className={cn('maxLength')}>{form.fieldName20.value.length}/500</span>
                            </div>

                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('title-field')}>
                            <h4>Настройка площадок</h4>
                        </div>
                    </div>
                    <div className={cn("form__row")}>

                        <Setup
                            disabled={!(form.fieldName20.value.length || form.fieldName19.value.length)}
                            fields={
                            [
                                new TopFormFields({
                                    fieldName: 'descr',
                                    fieldLabel: 'Краткое описание компании',
                                    fieldValue: '',
                                    maxlength: '200',
                                    fieldType: 'textarea',
                                    required: false
                                }),
                                new TopFormFields({
                                    fieldName: 'descr2',
                                    fieldLabel: 'Полное описание описание компании',
                                    fieldValue: '',
                                    maxlength: '500',
                                    fieldType: 'textarea',
                                    required: false
                                }),
                            ]
                        }
                               code={"descriptionSetup"}
                        />

                    </div>

                </div>
            </div>
        </>
    )
}))
export default Description;