class TopForm {
    source;
    name;
    active;
    fields = [];

    constructor(object = {}) {
        this.source = object.source || "";
        this.name = object.name || "";
        this.active = object.active || false;
        this.fields = object.fields || [];
    }

    getPlainObject() {
        return {
            source: this.source,
            name: this.name,
            active: this.active,
            fields: this.fields,
        }
    }
}

class TopFormFields {
    fieldName;
    fieldLabel;
    fieldValue;
    maxlength;
    fieldType;
    required;

    constructor(object = {}) {
        this.fieldName = object.fieldName;
        this.fieldLabel = object.fieldLabel;
        this.fieldValue = object.fieldValue;
        this.maxlength = object.maxlength;
        this.fieldType = object.fieldType;
        this.required = object.required;

    }

    getPlainObject() {
        return {
            fieldName: this.fieldName,
            fieldLabel: this.fieldLabel,
            fieldValue: this.fieldValue,
            maxlength: this.maxlength,
            fieldType: this.fieldType,
            required: this.required,
        }
    }
}
export {TopForm, TopFormFields}

