import React, {useEffect, useState} from "react";
import cn from "classnames";
import OkInputText from "../../../../common/OkInputText";
import {Button} from "primereact/button";
import {inject, observer} from "mobx-react";

const ContactEmailMulti = inject('geoStore')(observer((props) => {
    const {geoStore, field, code, source} = props;

    const saveFieldList = (fieldValue) => {
        geoStore.setSetupFieldValue(code, source, field.fieldName, fieldValue);
    }

    const multiProps = {
        ...props,
        ...{
            saveFieldList,
            geoStore,
            required: false,
        },
    }

    return(
        <ContactEmail {...multiProps}/>
    )
}))

const ContactEmailSingle = inject('geoStore')(observer((props) => {
    const {geoStore, fieldName} = props;

    const saveFieldList = (fieldValue) => {
        geoStore.setFormFieldValue(fieldName, fieldValue);
    }

    const singleProps = {
        ...props,
        ...{
            saveFieldList,
            required: geoStore.form[fieldName].required,
        },
    }
    return(
        <ContactEmail {...singleProps}/>
    )
}));

const ContactEmail = (props) => {
    const {fieldName, saveFieldList, required} = props;
    const [contactEmailList, setContactEmailList] = useState(['']);

    useEffect(()=>{
        saveFieldList(contactEmailList);
    }, [contactEmailList])

    const addItem = (key, value) => {
        setContactEmailList(prev => {
            let copy = [...prev];
            copy[key] = value;
            return copy
        })
    }

    const onDeleteItem = (key) => {

        let copy = [
            ...[],
            ...contactEmailList
        ];

        if (key > -1) {
            copy.splice(key, 1);
        }
        setContactEmailList(copy);
    }

    return (
        <>
            {contactEmailList.map((item, key)=>(
                <div className={cn("form__row")} key={key}>
                    <div className={cn('text-field')}>
                        <div className={cn("p-float-label")}>
                            <OkInputText
                                id={`${fieldName}_${key}`}
                                type="text"
                                name={`${fieldName}_${key}`}
                                value={contactEmailList[key]}
                                required={key === 0 && required}
                                onChange={(e) => {
                                    addItem(key, e.target.value)
                                }}
                            />

                            <label htmlFor={`${fieldName}_${key}`}>
                                Email адрес {key === 0 && required && <span>*</span>}
                            </label>
                        </div>
                    </div>
                    {key > 0 && <div className={cn('description-field')}>
                        <Button
                            label='Удалить'
                            className={cn('p-button-danger', 'p-button-outlined')}
                            onClick={() => {
                                onDeleteItem(key)
                            }}
                        />
                    </div>}
                </div>
            ))}

            <div className={cn("form__row")}>
                <div className={cn("btn-field")}>
                    <Button
                        disabled={!contactEmailList[0].length}
                        label='Добавить'
                        icon="pi pi-plus"
                        iconPos="left"
                        className={cn('p-button-outlined')}
                        onClick={() => {
                            const list = [
                                ...contactEmailList,
                                ...['']
                            ];
                            setContactEmailList(list);
                        }}/>
                </div>
            </div>
        </>
    );

}

export {
    ContactEmailMulti,
    ContactEmailSingle
}